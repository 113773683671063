a:link {
    color: "#103852"; 
    background-color: transparent;
    text-decoration: none;
  }

  a:visited {
    color: "#209095";
    background-color: transparent;
    text-decoration: none;
  }
  
  a:hover {
    color: "#206795";
    background-color: transparent;
    text-decoration:none;
    transform: translateY(-5px);
    transition: all 0.3s ease;
  }
  
  a:active {
    color: "#FF8A73";
    background-color: transparent;
    text-decoration: underline;
  }